import BasePage from '../../../../../../components/table_page';
import { ProcessLog } from '../../../../../../modules';
import Form from '../form';
import request from '../../../../../../utils/request';

export default {
  name: 'activity_manage',
  extends: BasePage,
  data() {
    return {
      params: {
        actType: 'project',
      },
    };
  },
  components: {
    Form,
    ProcessLog,
  },
  created() {
    this.getConfigList('tpm_activity_project_list');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 审批通过 approved
      // 审批中 approving
      // 活动关闭 closed
      // 流程追回 interrupt
      // 驳回 rejected
      const { approveStatus, processCode } = row;
      if (code === 'process_log' && !processCode) {
        return false;
      }
      if (
        approveStatus
        && code === 'edit'
        && (approveStatus === 'interrupt'
          || approveStatus === 'closed'
          || approveStatus === 'approving'
          || approveStatus === 'approved')
      ) {
        return false;
      }
      if (
        approveStatus
        && code === 'submit_approval'
        && (approveStatus === 'closed'
          || approveStatus === 'approving'
          || approveStatus === 'approved')
      ) {
        return false;
      }

      return true;
    },
    // 活动关闭前置
    beforeButtonClick({ val }) {
      let state = true;
      if (val.code === 'activities_close' && this.selectRow.length === 0) {
        this.$message('最少选择一条数据操作');
        state = false;
      }

      return state;
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
          budgetState: this.budgetState,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (code === 'submit_approval') {
        request
          .get('/tpm/tpmActController/query', {
            id: row.actId || row.id,
          })
          .then((res) => {
            if (res.success) {
              const rowData = { ...res.result };
              rowData.budgetControlVos = rowData.editBudgetVos;
              rowData.saveType = '5';
              this.onSubmit({ row: rowData, submitUrl: '/tpm/tpmActController/approve' });
            }
          });
      } else if (code === 'activities_close') {
        this.$confirm('确认关闭活动吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request.post('/tpm/tpmActController/closed', { ids: row.map((v) => v.id) }).then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '操作成功',
                });
                this.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            });
          });
      } else if (code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
    },
    // 提交审批回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
